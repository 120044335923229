// @ts-nocheck
import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import AppLayout from '../components/layout/AppLayout';
import { ProjectMain } from '../components/old-version/ProjectMain/ProjectMain';
import { ProjectHierView } from '../components/old-version/ProjectHierView/ProjectHierView';
import { PublicProjectsView } from '../components/old-version/PublicProjectsView/PublicProjectsView';

const Project = () => {
  return (
    <AppLayout>
      <Router default="/">
        <ProjectHierView path="/project/:projectId" isUserDashboard={true} />
        <ProjectMain path="/project/:projectId/workspace/:workspaceId" />
        <PublicProjectsView path="/project/publicProjects" />
      </Router>
    </AppLayout>
  );
};

export default Project;
